<template>
    <div class="flex justify-center" data-aos="fade-up">
        <div class="leasing mw-1920">
            <div class="leasing-left">
                <div class="leasing-title">
                    <h1 data-aos="fade-up" class="top-h1"> {{ Pages.data.blog5[`title_${$i18n.locale}`]}} </h1>
                    <p data-aos="fade-up" class="top-p">{{ Pages.data.blog5[`description_${$i18n.locale}`]}}</p>
                </div>
                <div class="leasing-item">
                    <p data-aos="fade-up">{{ Pages.data.blog5[`text_${$i18n.locale}`]}}</p>
                    <div class="flex items-center leasing-buttons">   
                        <button class="button " data-aos="fade-up">{{Pages.data.blog5[`button1_${$i18n.locale}`]}}</button>
                        <button class="button blue" @click="$router.push('/terms')" data-aos="fade-up" data-aos-delay="200">{{Pages.data.blog5[`button2_${$i18n.locale}`]}}</button>
                    </div>
                </div>
            </div>
             <div class="leasing-right" v-if="Pages.files.blog5Image">
                <!-- <div  class="leasing-dinamic"  data-aos="opacity-anime1"> 
                    <img :src="Pages.files.blog5Image? `https://avior.prestigeautofl.com${Pages.files.blog5Image}`: require(`@/assets/icons/leasingdown.svg`)" alt="">
                </div> -->
                 
                <div class="leasing-anime1 " data-aos="opacity-anime1">
                    <img :src="`https://avior.prestigeautofl.com${Pages.files.blog5Image1}`" class="img" alt="" >
                </div>
                <div class="leasing-anime2 " data-aos="opacity-anime3"   >
                    <img :src="`https://avior.prestigeautofl.com${Pages.files.blog5Image2}`" class="img" alt="">
                </div>
                <div class="leasing-anime3" data-aos="opacity-anime2" >
                    <img :src="`https://avior.prestigeautofl.com${Pages.files.blog5Image3}`" class="img" alt="">
                </div>
                <div class="leasing-clouds" data-aos="opacity-anime1">
                    <img   :src="`https://avior.prestigeautofl.com${Pages.files.blog5Cloud}`" class=" lasingAnime" alt="">
                    <img   :src="`https://avior.prestigeautofl.com${Pages.files.blog5Cloud}`" class=" lasingAnime delay-1" alt=""> 
                </div>
                 
            </div>
            <!-- <div class="leasing-right" v-else>
                <div class="leasing-anime1 desktop" data-aos="opacity-anime1">
                    <img src="@/assets/img/leasinganime1.svg" class="img" alt="" >
                </div>
                <div class="leasing-anime2 desktop" data-aos="opacity-anime2"   >
                    <img src="@/assets/img/leasinganime2.svg" class="img" alt="">
                </div>
                <div class="leasing-anime3 desktop" data-aos="opacity-anime3" >
                    <img src="@/assets/img/leasinganime3.svg" class="img" alt="">
                </div>
                <img src="@/assets/img/leasing.svg" class="phone" alt="AAA"> 
                <div class="leasing-clouds" data-aos="opacity-anime1">
                    <img   src="@/assets/img/lizingClouds.svg" class="desktop lasingAnime" alt="">
                    <img   src="@/assets/img/lizingClouds.svg" class="desktop lasingAnime delay-1" alt=""> 
                </div>
            </div> -->
        </div>
    </div>
</template>
<script> 

export default {
    computed:{
         Pages() {
            return this.$store.state.Home
        }
    }
}
</script>
