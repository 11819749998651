<template>
<div class="about-company-view owf-h">
    <div class="flex justify-center">
        <div class="about-company mw-1920  ">
            <div class="about-company-items"  :class="rigtImage?'flex-direction-reserve':''" data-aos="fade-up">
                <div class="about-company-item">
                    <img  :src="data.image ?`https://avior.prestigeautofl.com/${data.image}`: require('@/assets/img/aboutCompany.svg') " alt="Company">
                </div>
                <div class="about-company-item">
                    <h1 > {{data[`title_${$i18n.locale}`]}} </h1>
                    <div class="page-item" v-html="data[`description_${$i18n.locale}`]"></div>
                </div>
                
            </div>
        </div>
    </div>
</div>
</template>
<script>  

export default {
    name:'Page2', 
    props:['data', 'rigtImage'],
    data(){
        return{
            input:'',
            kredit_type:'pledge',
            a:10,
            about:[
                {title:'Про нас', text:'Augue tempus amet vestibulum sapien. Ultrices aliquam id feugiat aliquam erat id. Varius porttitor non tellus sit et augue. Et sit aenean neque lectus scelerisque pellentesque. Arcu aliquam sapien nunc, tempor. Faucibus nunc malesuada mi accumsan, dictum cras. Est non et velit pretium sit at ac praesent cursus. Id erat ornare ac facilisis convallis quam congue egestas senectus. Amet urna id ullamcorper fringilla. A quam.'},
            ]
        }
    }
 
    
}
</script>


<style lang="scss">
.about-company {
    width: 100%;
    padding: 60px 60px;

    .about-company-items {
        display: flex;
        flex-wrap: wrap;
        .about-company-item {
            width: 50%;
            padding: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1 {
                font-family: Stem-Medium;
                font-size: 40px;
                line-height: 42px; 
                font-feature-settings: 'tnum' on, 'lnum' on;
                color: var(--other-black);
            }
            p{
                font-family: Stem-Regular;
                font-size: 1.125rem;
                line-height: 24px; 
                font-feature-settings: 'tnum' on, 'lnum' on;
                color: var(--white-black);
                margin-top: 20px;
            }
            img{
                width: 80%;
                margin: 0 auto;
            }

            
        }
    }
     .flex-direction-reserve{ 
            flex-direction: row-reverse;
        }
}
.about-carid-count{
    padding: 100px 0px;
}

.about-company-top-view {
    width: 100%;
    text-align: center;
    background: var(--bg-top);
    padding: 100px  120px;
    position: relative;
}

.stock-mover {
    transform: translateY(-100px);
}

.about-company-top-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        width: 30%;
    }
} 
.about-contact{
    padding:20px 90px;
    display: flex;
    width: 100%;
    position: relative;
    .about-contact-item{
        width: 50%;
        padding: 60px;
        position: relative;
        z-index: 1;
    }
    .about-contact-cover{
        position: absolute;
        width: 100%;
        height: 50%;
        bottom: 0;
        left: 0;
        background: var(--bg-top);
        z-index: 0;
    }
}

@media screen and(max-width:1100px) {
    .about-company-top-view {
        padding: 100px  20px;
    }
    .about-company{
        padding: 30px 20px;
    }
    .about-company{
        padding: 60px 0px;
    }
    .about-contact{ padding:20px 0px; }
}
@media screen and(max-width:950px) {
    .about-company-top-view {
        padding: 100px  20px;
    }
    .about-company { 

    .about-company-items {
        display: flex;
        flex-wrap: wrap; 
        .about-company-item {
            width: 100%;
            padding:30px 20px; 

            h1 {
                text-align: center;
            }
            p{
               text-align: center;
            }
            img{
                width: 50%;
                margin: 0 auto;
            }

            
        }
    }
     
    }
    .about-contact{ 
        flex-wrap: wrap; 
        padding-top: 0;
    .about-contact-item{
        width: 100%;
        padding: 40px; 
        display: flex;
        justify-content: center;
        img{
            width: 60%;
        }
    } 
    .about-contact-cover{ 
        height: 30%; 
    }
     .about-contact-cover2{ 
        height: 20%; 
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--bg-top);
        z-index: 0;

    }
}
}

@media screen and(max-width:768px) {
    .about-company-top-view {
        padding: 60px 20px;
    }

    .about-company-top-bg {
        display: none;
    }
    .about-company { 

    .about-company-items {
        display: flex;
        flex-wrap: wrap;
        .about-company-item { 

            h1 {
                    font-size: 24px;
                    line-height: 28px;
            }
            p{
                font-family: Stem-Regular;
                font-size: 0.875rem;
                line-height: 1rem; 
                font-feature-settings: 'tnum' on, 'lnum' on;
                color: var(--white-black);
                margin-top: 20px;
            }
            img{
                width: 70%;
                margin: 0 auto;
            }

            
        }
    }
     .flex-direction-reserve{ 
            flex-direction:row-reverse;
        }
}
.about-contact{ 
        flex-wrap: wrap; 
        padding-top: 0;
    .about-contact-item{ 
        padding: 40px 20px;  
        img{
            width: 70%;
        }
    } 
    
}
}
</style>
