<template>
<div class="terms-view">
    <div class="flex justify-center">
        <div class="terms mw-1920">
            <div class="terms-item">
                <h1 data-aos="fade-up"> {{data[`title_${$i18n.locale}`]}} </h1>
                <div class="w-full page-item" data-aos="fade-up" v-html="data[`description_${$i18n.locale}`]"  >

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
  export default {
      name: 'terms',
      props:['data']

  }
</script>

<style lang="scss">
.terms {
    width: 100%;
    padding: 30px 120px;

}

.terms-top-view {
    width: 100%;
    text-align: center;
    background: var(--bg-top);
    padding: 100px 120px;
    position: relative;
}

.stock-mover {
    transform: translateY(-100px);
}

.terms-top-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        width: 30%;
    }
}
.terms-item{
    padding: 30px 0px;
    h1{
        font-family: Stem-Medium;
        font-size: 30px;
        line-height: 35px; 
        color: var(--other-black);

    }
    p{
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 24px; 
 

        color: var(--other-black);
        margin-top: 20px;
    }
}


@media screen and(max-width:1100px) {
    .terms-top-view {
        padding: 100px 20px;
    }

    .terms {
        padding: 30px 20px;
    }
}

@media screen and(max-width:950px) {
    .terms-top-view {
        padding: 100px 20px;
    }

}

@media screen and(max-width:768px) {
    .terms-top-view {
        padding: 60px 20px;
    }

    .terms-top-bg {
        display: none;
    }
    .terms-item{
    padding: 20px 0px;
    h1{
        font-size: 20px;
        line-height: 1.5rem;

    }
    p{
        font-size: 0.75rem;
        line-height: 1.125rem;
    }
}
}
</style>
