<template>
    <div class="flex justify-center">
        <div class="about-contact mw-1920" data-aos="fade-up">
            
            <div class="about-contact-item" >
                    <img src="@/assets/img/aboutCompany.svg" alt="Company">
            </div>
            <div class="about-contact-item">
                    <ContactCard  />
            </div>
            <div class="about-contact-cover"></div>
            <div class="about-contact-cover2 phone"></div>
        </div>
    </div>
</template>
<script>
import  ContactCard from './ContactCard.vue'
export default {
    name:'avout-company',
    components:{ 
        ContactCard
    },   
}
</script>
