<template>
<div class="leasing-page-view  owf-h">
    <div class="leasing-page-top-view pt-200">
        <h1 class="top-h1 tal"  data-aos="fade-down">Контакти</h1>
        <p class="top-p mt-20px tal"  data-aos="fade-down">Чекаємо на Ваші звернення</p>
        <div class="leasing-page-top-bg">
            <img src="@/assets/img/stockbg.svg" alt="">
        </div>
    </div>
    
    <div class="flex justify-center">
        <div class="leasing-page mw-1920  ">
            <div class="leasing-page-items">
                <div class="leasing-page-item-left leasing-page-item">
                    <div class="leasing-page-phone">
                        <h1  data-aos="fade-up">+ 380 87 676 87 67</h1>
                        <p  data-aos="fade-up">Безкоштовно з будь-яких операторів</p>
                    </div>
                    <div class="leasing-page-mail">
                        <p  data-aos="fade-up">Служба підтримки: <span>email@gmail.com</span> </p> 
                        <p  data-aos="fade-up">Служба підтримки: <span>email@gmail.com</span> </p> 
                        <p  data-aos="fade-up">Служба підтримки: <span>email@gmail.com</span> </p> 
                    </div>
                    <div class="leasing-page-adress">
                        <p  data-aos="fade-up">Україна, м. Київ, вул. Вадима Гетьмана, 20</p>
                    </div>
                </div> 
                <div class="leasing-page-item"  >
                    <div class="leasing-page-card">
                        <p>Ми повернемося з відповіддю незабаром:</p>
                        <input type="text" placeholder="Електронна скринька *">
                        <textarea name="AAA" id="" cols="30" rows="10" placeholder="Текст повідомлення:"></textarea>
                        <div class="flex justify-center">
                            <button class="button">Відправити</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>  
export default {
    name:'leasing-page',
    components:{ 
    }
    
}
</script>


<style lang="scss">
.leasing-page {
    width: 100%;
    padding: 0px 120px;

    .leasing-page-items {
        display: flex;
        flex-wrap: wrap;
         
    }
    .leasing-page-item {
        width: 50%;
    }
    .leasing-page-item-left {
        padding: 60px 0px;
    }
    .leasing-page-phone{
        h1{
            font-family: Stem-Medium;
            font-size: 20px;
            line-height: 1.5rem; 
            color: var(--other-black);
        }
        p{
            font-family: Stem-Medium;
            font-size: 0.875rem;
            line-height: 1rem; 
            color: var(--white-black);
            margin-top: 10px;
        }
    }
    .leasing-page-mail{
        padding: 50px 0px 60px 0px;
        p{
            font-family: Stem-Medium;
            font-size: 20px;
            line-height: 1.5rem;
            display: flex;
            align-items: center;
            text-align: center;
            color: var(--white-black);
            margin-top: 10px;
            span{
                color: var(--other-black);
                margin-left: 10px;
            }
        }
    }
    .leasing-page-adress{
        p{
            font-family: Stem-Medium;
            font-size: 20px;
            line-height: 1.5rem; 
            color: var(--other-black);
        }
    }
    .leasing-page-card{
        width: 100%;
        max-width: 600px;
        padding: 40px 40px;
        box-shadow: 0px 6px 16px -8px rgba(0, 72, 153, 0.12), 0px 9px 28px rgba(0, 72, 153, 0.05), 0px 12px 48px 16px rgba(0, 72, 153, 0.03);
        border-radius: 10px;
        background: var(--white-bg);
        transform: translateY(-60px);
        p{
            font-family: Stem-Regular;
            font-size: 20px;
            line-height: 1.5rem; 
            color: var(--other-black); 
        }
        input{
            width: 100%;
            padding: 20px 30px;
            margin-top: 25px;
            font-family: Stem-Regular;
            font-size: 1.125rem;
            line-height: 1.25rem; 
            color: var(--white-black);
            border: 1.5px solid #DDDFE2;
            box-sizing: border-box;
            border-radius: 4px;
            outline: none;
        }
        textarea{
            width: 100%;
            height: 120px;
            margin-top: 20px;
            border: 1.5px solid #DDDFE2;
            box-sizing: border-box;
            border-radius: 4px;
            font-family: Stem-Regular;
            font-size: 1.125rem;
            line-height: 1.25rem; 
            color: var(--white-black);
            outline: none;
            padding: 20px 30px;

        }
        button{
            margin-top: 40px;
        }
    }
}

.leasing-page-top-view {
    width: 100%;
    text-align: center;
    background: var(--bg-top);
    padding: 100px  120px;
    position: relative;
}

.stock-mover {
    transform: translateY(-100px);
}

.leasing-page-top-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        width: 30%;
    }
}

@media screen and(max-width:1100px) {
    .leasing-page-top-view {
        padding: 100px  20px;
    }
    .leasing-page{
        padding: 0px 20px;
    }
}
@media screen and(max-width:950px) {
    .leasing-page-top-view {
        padding: 100px  20px;
    }
    .leasing-page { 
    .leasing-page-item {
        width: 100%;
        padding: 20px 0px;
    } 
    .leasing-page-card{ 
        transform: translateY(0px);
    }
}
}

@media screen and(max-width:768px) {
    .leasing-page-top-view { padding: 60px 20px 100px 20px; .tal{text-align: center !important;} }
    .leasing-page-top-bg { display: none; }
    .leasing-page { 
    .leasing-page-items{
        flex-direction: column-reverse;
    }
    .leasing-page-item{
        padding: 0;
    }
     .leasing-page-card{ 
        transform: translateY(-50px);
    }
    .leasing-page-item-left {
        padding: 40px 0px;
    }
    .leasing-page-phone{
        h1{ 
            font-size: 1rem;
            line-height: 1.125rem;  
        }
        p{ 
            font-size: 0.75rem;
            line-height: 1.125rem;  
        }
    }
    .leasing-page-mail{
        padding: 30px 0px 40px 0px;
        p{ 
            font-size: 1rem;
            line-height: 1.125rem; 
            text-align: left; 
            
        }
    }
    .leasing-page-adress{
        p{ 
            font-size: 1rem;
            line-height: 1.125rem;  
        }
    }
    .leasing-page-card{ 
        padding: 25px;
        p{ 
            font-size: 1rem;
            line-height: 1.125rem;  
        }
        input{ 
            padding: 15px 20px;
            margin-top: 25px; 
            font-size: 1rem;
            line-height: 1.125rem;  
        }
        textarea{ 
            font-size: 1rem;
            line-height: 1.125rem;  
            padding: 15px 20px;

        }
        button{
            margin-top: 40px;
        }
    }
}
}
</style>
