<template>
    <div class="flex justify-center" id="prevagi">
        <div class="benefits mw-1920 w-full">
            <div class="benefits-title">
                <h1 data-aos="fade-up"> {{ Pages.data.blog6[`title_${$i18n.locale}`]}} </h1>
                <p data-aos="fade-up"> {{ Pages.data.blog6[`description_${$i18n.locale}`]}} </p>
            </div>
            <div class="benefit-items">
                <div class="benefit-item" data-aos="fade-up">
                    <div class="benefit-card">
                        <a :href="Pages.data.blog6.url1" >
                        <div class="benefit-card-img">
                            <img :src="Pages.files.blog6Image1? `https://avior.prestigeautofl.com${Pages.files.blog6Image1}`: require(`@/assets/icons/handclock.svg`)" alt="">
                        </div>
                        <div class="benefit-card-text">
                            <p>{{ Pages.data.blog6[`title1_${$i18n.locale}`]}}</p>
                        </div>
                        </a>
                    </div>
                </div>
                <div class="benefit-item" data-aos="fade-up" data-aos-delay="200">
                    <div class="benefit-card">
                        <a :href="Pages.data.blog6.url2" >
                        <div class="benefit-card-img"> 
                            <img :src="Pages.files.blog6Image2? `https://avior.prestigeautofl.com${Pages.files.blog6Image2}`: require(`@/assets/icons/leasinghuman.svg`)" alt="">
                        </div>
                        <div class="benefit-card-text">
                            <p>{{ Pages.data.blog6[`title2_${$i18n.locale}`]}}</p>
                        </div>
                        </a>
                    </div>
                </div>
                <div class="benefit-item" data-aos="fade-up" data-aos-delay="400">
                    <div class="benefit-card">
                        <a :href="Pages.data.blog6.url3" >
                        <div class="benefit-card-img"> 
                            <img :src="Pages.files.blog6Image3? `https://avior.prestigeautofl.com${Pages.files.blog6Image3}`: require(`@/assets/icons/leasingclock.svg`)" alt="">
                        </div>
                        <div class="benefit-card-text">
                            <p>{{ Pages.data.blog6[`title3_${$i18n.locale}`]}}</p>
                        </div>
                        </a>
                    </div>
                </div>
                <div class="benefit-item" data-aos="fade-up" data-aos-delay="600">
                    <div class="benefit-card">
                        <a :href="Pages.data.blog6.url4" >
                        <div class="benefit-card-img"> 
                            <img :src="Pages.files.blog6Image4? `https://avior.prestigeautofl.com${Pages.files.blog6Image4}`: require(`@/assets/icons/leasingkredit.svg`)" alt="">
                        </div>
                        <div class="benefit-card-text">
                            <p>{{ Pages.data.blog6[`title4_${$i18n.locale}`]}}</p>
                        </div>
                        </a>
                    </div>
                </div>
                <div class="benefit-item" data-aos="fade-up" data-aos-delay="800">
                    <div class="benefit-card">
                        <a :href="Pages.data.blog6.url5" >
                        <div class="benefit-card-img"> 
                            <img :src="Pages.files.blog6Image5? `https://avior.prestigeautofl.com${Pages.files.blog6Image5}`: require(`@/assets/icons/leasingdown.svg`)" alt="">
                        </div>
                        <div class="benefit-card-text">
                            <p>{{ Pages.data.blog6[`title5_${$i18n.locale}`]}}</p>
                        </div>
                        </a>
                    </div>
                </div>

            </div>
            <div class="benefit-button">
                <a :href="Pages.data.blog6.button_url">
                <button class="button" data-aos="fade-up">{{Pages.data.blog6[`button_${$i18n.locale}`]}}</button>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed:{
         Pages() {
            return this.$store.state.Home
        }
    },
}
</script>