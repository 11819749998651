<template>
    <div class="flex justify-center">
        <div class="stock mw-1920">
            <div class="stock-title">
                 <h1 data-aos="fade-up">{{ Pages.data.blog4[`title_${$i18n.locale}`]}}</h1>
                <p data-aos="fade-up">{{ Pages.data.blog4[`description_${$i18n.locale}`]}}</p>
            </div> 
            <div class="flex justify-center">
            <div class="stock-list">
                <div class="stock-card-item" v-for="item in StocksList.data" :key="item.id">
                    <div class="stock-phone-bg" data-aos="zoom-in"  data-aos-easing="ease-in-sine" :style="{'background':`url(https://avior.prestigeautofl.com/${item.image})`}">
                        <div class="stock-phone-bg-cover"></div>
                    </div>
                    <div class="stock-card" data-aos="fade-up"  data-aos-easing="ease-in-sine">
                        <div class="stock-card-bg" :style="{'background':`url(https://avior.prestigeautofl.com/${item.image})`}"></div>
                        <div class="stock-card-text">
                            <h1> {{item[`title_${$i18n.locale}`]}} </h1>
                            <p> {{item[`title_${$i18n.locale}`]}}  </p>
                        </div>
                        <div class="stock-card-bottom">
                            <button @click="$router.push(`/stocks/${item.id}`)">{{$t('show_more')}}</button>
                            <p>{{item.start_date.split('-')[2] }}.{{item.start_date.split('-')[1]}} - {{item.end_date.split('-')[2]}}.{{item.end_date.split('-')[1]}}.{{item.end_date.split('-')[0]}}</p>
                        </div>
                    </div>
                </div> 
                <div class="w-full flex justify-center mt-40px">
                    <button  class="button" @click="$router.push('/stocks')">{{Pages.data.blog4[`button_${$i18n.locale}`]}}</button>
                </div>
            </div>
            </div> 
           
        </div>
    </div>
</template>
<script> 

 export default { 
     data() {
         return {    
             StocksList:[]
         }
     },
     computed:{
         Pages() {
            return this.$store.state.Home
        }
    },
     methods:{
         GetStocks(){
             try{
                 this.axios.get('/stock?per_page=6').then(res=>{this.StocksList=res.data.data})
             }
             catch{
                 console.log("AAA")
             }
             
         }
     },
     mounted(){ 
         this.GetStocks()
     }
   
 }
</script>
