<template>
<div class=" w-full white-bg" style="min-height:500px" v-loading="loading">
    <div class="w-full" v-if="Page">
    <div class="flex justify-center  w-full">
        <div class="faq-view owf-h mw-1920 w-full">
            <div class="faq-top-view pt-200">
                <h1 class="top-h1" data-aos="fade-right"> {{Page[`title_${$i18n.locale}`]}} </h1>
                <p class="top-p mt-20px" data-aos="fade-left"> {{Page[`description_${$i18n.locale}`]}} </p>
                <div class="faq-top-bg">
                    <img src="@/assets/img/stockbg.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="w-full" v-for="(item, i) in Page.blogs" :key="i">
        <Page1 class="w-full" v-if="item.type==1" :data="item" />
        <Page2 class="w-full" v-if="item.type==2" :rigtImage="i%2==0?false:true" :data="item" />
    </div>
        <SystemLinks v-if="Page.links_component" />
        <Credit v-if="Page.credit_component" />
        <Stock v-if="Page.stocks_component" />
       
        <Leasing v-if="Page.leasing_component"  />
        <Benefit v-if="Page.icon_component" />
        <Contact v-if="Page.contact_component" />
    </div>
   <div class="w-full" v-else>
       <LeasingPage />
   </div>

</div>
</template>

<script>
import Page1 from '../components/Pages/Page1.vue'
import Page2 from '../components/Pages/Page2.vue'
import SystemLinks from '../components/SystemLinks.vue'
import Leasing from '../components/Leasing.vue'
import Stock from '../components/Stock.vue'
import Benefit from '../components/Benefit.vue'
import Contact from '../components/Contact.vue'
import Credit from '../components/Credit.vue'


import LeasingPage from '../components/Pages/Leasing.vue'

export default {
    name: 'Faq',
    components: {
        Page1, Page2, SystemLinks, Leasing, Stock, Benefit, Contact, Credit,  LeasingPage
    },
    data() {
        return {
            loading: true,
            Page: [],
        }
    },
    computed: {
        Pages() {
            return this.$store.state.Pages
        }
    },
    watch:{
        Pages(){
            if(this.Pages.filter(a=>{ return a.url==this.$route.params.type})[0]){
            this.Page=this.Pages.filter(a=>{ return a.url==this.$route.params.type})[0]
            this.loading=false
            }
            else{
                this.$router.push('/404')
            }
        },
        '$route.params.type'(){
            if(this.Pages.filter(a=>{ return a.url==this.$route.params.type})[0]){
            this.Page=this.Pages.filter(a=>{ return a.url==this.$route.params.type})[0]
            this.loading=false
            }
            else{
                this.$router.push('/404')
            }
        }
    },
    mounted() {
        
        if(this.Pages.filter(a=>{ return a.url==this.$route.params.type})[0]){
            this.Page=this.Pages.filter(a=>{ return a.url==this.$route.params.type})[0]
            this.loading=false
        }
       
    }

}
</script>

<style lang="scss">
.faq-top-view {
    width: 100%;
    text-align: center;
    background: var(--bg-top);
    padding: 100px 120px;
    position: relative;
}

.stock-mover {
    transform: translateY(-100px);
}

.faq-top-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        width: 30%;
    }
}

.page {
    width: 100%;
    padding: 50px 0px;

    .title-h1 {
        color: var(--other-black);
        font-family: Stem-Medium;
        font-size: 40px;
        line-height: 42px;
        font-feature-settings: 'tnum'on, 'lnum'on;
    }

    .title-p {
        font-family: Stem-Medium;
        font-size: 24px;
        line-height: 28px;
        color: var(--white-black);

    }

    .simple-p {
        font-family: Stem-Regular;
        font-size: 1.125rem;
        line-height: 27px;
        color: var(--white-black);
        font-feature-settings: 'tnum'on, 'lnum'on;
    }

    .seo {
        width: 100%;
        padding: 0px 120px;
        text-align: center;
    }

    .first-block {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 50px 120px;
        background:var(--white-gray-bg);

        .firts-block-items {
            width: 50%;
            text-align: center;
            padding: 20px;
        }

        .first-block-image {
            width: 50%;
            padding: 50px;

            display: flex;
            justify-content: center;

            img {
                width: 100%;
            }
        }
    }

    .bg-fff {
        background: var(--white-bg);
    }

}

@media screen and(max-width:1100px) {
    .faq-top-view {
        padding: 100px 20px;
    }

    .page {
        width: 100%;
        padding: 50px 0px;

        .seo {
            padding: 0px 20px;
        }

        .first-block {
            padding: 50px 20px;

            .first-block-image {
                width: 50%;
                padding: 20px;

                display: flex;
                justify-content: center;

                img {
                    width: 100%;
                }
            }
        }

    }

}

@media screen and(max-width:950px) {
    .faq-top-view {
        padding: 100px 20px;
    }

}

@media screen and(max-width:768px) {
    .faq-top-view {
        padding: 60px 20px;
    }

    .faq-top-bg {
        display: none;
    }

    .faq-top-view {
        padding: 100px 20px;
    }

    .page {
        width: 100%;
        padding: 50px 0px;

        .seo {
            padding: 0px 20px;
        }

        .first-block {
            padding: 50px 20px;
            flex-wrap: wrap;

            .first-block-image {
                width: 100%;
                padding: 20px;
            }

            .firts-block-items {
                width: 100%;
                padding: 20px;
            }

        }

    }

}
</style>
